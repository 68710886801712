.users-subscriptions-body-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 25px;
  position: relative;
}

.users-subscriptions-body-component .users-subscriptions-table-container {
  width: 90%;
  background-color: white;
  padding: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 -15px;
}

.users-subscriptions-table-container .users-subscriptions-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  text-align: left;
}

.users-subscriptions-table-container .users-subscriptions-table th {
  background-color: var(--primary);
  color: white;
  padding: 10px;
  font-weight: bold;
  padding-top: 15px;
}

.users-subscriptions-table-container .users-subscriptions-table .first-column {
  border-top-left-radius: 15px;
}

.users-subscriptions-table-container .users-subscriptions-table .last-column {
  border-top-right-radius: 15px;
}

.users-subscriptions-table-container .users-subscriptions-table tr {
  border-bottom: 1px solid #e0e0e069;
}

.users-subscriptions-table-container .users-subscriptions-table td {
  padding: 10px;
}

.users-subscriptions-table-container .users-subscriptions-table td,
.users-subscriptions-table-container .users-subscriptions-table th {
  white-space: nowrap;
}

.users-subscriptions-table-container .users-subscriptions-table .user-name {
  color: var(--primary);
  text-decoration: underline;
}

.users-subscriptions-table-container .users-subscriptions-table .status {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
}

.users-subscriptions-table-container .users-subscriptions-table .status.active {
  background-color: var(--success);
}

.users-subscriptions-table-container .users-subscriptions-table .status.in-active {
  background-color: var(--danger);
}

.users-subscriptions-table-container .users-subscriptions-table tr {
  transition: all 0.3s ease;
}

.users-subscriptions-table-container .users-subscriptions-table tr:hover {
  background-color: #f1f1f1;
}

.users-subscriptions-table-container .users-subscriptions-table tr .no-users-subscriptions-available {
  text-align: center;
  font-weight: bold;
  padding: 25px 0;
}

@media (max-width: 600px) {
  .users-subscriptions-body-component .users-subscriptions-table-container {
    width: 100%;
  }

  .users-subscriptions-body-component {
    padding: 15px;
  }

  .users-subscriptions-table-container .users-subscriptions-table {
    font-size: 14px;
  }

  .users-subscriptions-table-container .users-subscriptions-table th,
  .users-subscriptions-table-container .users-subscriptions-table td {
    padding: 8px;
  }

  .users-subscriptions-table-container .users-subscriptions-table .status {
    font-size: 12px;
    padding: 3px 6px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .users-subscriptions-body-component .users-subscriptions-table-container {
    width: 100%;
  }
  
  .users-subscriptions-table-container .users-subscriptions-table {
    font-size: 15px;
  }

  .users-subscriptions-table-container .users-subscriptions-table th, 
  .users-subscriptions-table-container .users-subscriptions-table td {
    padding: 10px;
  }

  .users-subscriptions-table-container .users-subscriptions-table .status {
    font-size: 13px;
    padding: 4px 8px;
  }
}
.card-dropdown-component {
  min-width: 150px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  position: absolute;
  top: 100%;
  right: 10px;
  overflow: hidden;
  padding: 5px;
  list-style: none;
  border-radius: 3px;
}

.card-dropdown-component.card-dropdown-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s;
}

.card-dropdown-component.card-dropdown-in-active {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s;
}

.card-dropdown-component.rtl {
  right: -600%;;
}
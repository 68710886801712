.new-subscription-container {
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
}

.new-subscription-container .plans-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  position: relative;
}

.new-subscription-container .card {
  max-height: 400px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.new-subscription-container .card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.new-subscription-container .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.new-subscription-container .card p {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
}

.new-subscription-container .card-button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.new-subscription-container .card-button:hover {
  background-color: #0056b3;
}

.reset-password-component {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.reset-password-form {
  width: 450px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  border-radius: 5px;
  padding: 30px 20px 10px;
}

@media (max-width: 600px) {
  .reset-password-form {
    background-color: transparent;
    box-shadow: none;
  }
}
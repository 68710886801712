.description-component {
  width: 100%;
  min-height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.description-header-container {
  width: 100%;
  padding: 10px 20px 10px 10px;
  /* border-bottom: 1px solid #444444; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.description-header-label {
  font-size: 18px;
  font-weight: bold;
  color: #444444;
}

.header-icon-container {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-description-icon {
  font-size: 24px;
  color: #444444;
  cursor: pointer;
  transition: all 0.1s ease;
}

.edit-description-icon:hover {
  color: black;
}

.description-body-container {
  text-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 10px;
}

.description-body-container .description-value {
  color: #444444;
  padding-bottom: 5px;
}

.set-description-container {
  width: 100%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  margin-top: 10px;
  display: none;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.set-description-container.write-mode {
  display: flex;
}

.description-body-container .set-description {
  font-weight: bold;
  width: 100%;
  min-height: 100px;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 10px;
  resize: none;
  background-color: transparent;
  border: 1px solid black;
}

.description-done-container {
  /* background-color: gray; */
  width: 25%;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.description-done {
  color: var(--primary);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 10px 20px;
}

.description-done.loading {
  opacity: 0.5;
  pointer-events: none;
}

.description-done:hover {
  color: white;
  background-color: var(--primary);
}

.set-description-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: none;
}

.set-description-loader.active {
  width: 20px;
  height: 20px;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {

}

@media (min-width: 601px) and (max-width: 900px) {

}

@media (min-width: 901px) {

}
.password-input-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.password-title {
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.password-title div span {
  color: var(--danger);
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  width: 100%;
  color: black;
  padding: 10px 7px;
  border: 2px solid #B0BEC5;
  font-size: 16px;
  font-weight: bold;
  border-radius: 3px;
  /* outline-color: rgb(168, 168, 168); */
  outline-color: var(--primary);
}

.toggle-password-visibility {
  display: flex;
  align-items: center;
  position: absolute;
  right: 8px;
  cursor: pointer;
}

.error-label {
  color: var(--danger);
  font-size: 12px;
  font-weight: bold;
  padding-left: 5px;
}

@media (max-width: 600px) {
  .password-title {
    font-size: 12px;
    margin-bottom: 0;
  }
  
  .password-input {
    font-size: 14px;
  }
  
  .error-label {
    font-size: 10px;
  }
}
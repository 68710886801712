.admin-card-component {
  /* width: 230px;
  height: 300px; */
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 5px;
}

.admin-card-header {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: var(--primary);
  color: white;
  border-radius: 5px;
  position: relative;
}

.admin-card-more-container {
  width: 100%;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
}

.admin-card-more-container .show-profile-container {
  width: 100%;
}

.admin-card-more-container .show-profile-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.admin-card-more {
  cursor: pointer;
  transition: all 0.2s ease;
}

.admin-card-more:hover {
  color: #B0BEC5;
}

.admin-card-img-container {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #B0BEC5;
}

.admin-card-img-container .profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}


.admin-card-fullname {
  font-size: 15px;
  font-weight: bold;
}

.admin-card-body {
  width: 100%;
  height: calc(100% - 50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-card-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding: 5px;
}

.admin-card-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-card-username {
  cursor: pointer;
}

.admin-card-info p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.admin-card-info p .danger {
  color: var(--danger)
}

.admin-card-info p .success {
  color: var(--success);
}

.admin-card-btns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 40px;
}

.card-transaction-btn {
  width: 35px;
  height: 35px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
}

.message-box-btn-container {
  width: 100%;
  height: 50px;
  margin-top: 25px;
}

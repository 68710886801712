.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  /* font-weight: bold; */
  padding: 10px;
  gap: 10px;
}

.main-container .loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: none;
}

.main-container .loading-container.active {
  display: flex;
}

.main-container .welcome-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  letter-spacing: 1px;
  border-radius: 5px;
  color: white;
  background: linear-gradient(45deg, var(--primary), var(--second));
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.main-container .welcome-container .welcome-message {
  margin-top: 10px;
  font-weight: bold;
}

.main-container .welcome-container .welcome-img {
  width: 80px;
  height: 100%;
}

.main-container .check-users-subscriptions-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
  padding: 25px;
  letter-spacing: 1px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.main-container .check-users-subscriptions-container .note-message {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.main-container .check-users-subscriptions-container .note-message .icon-container {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-container .check-users-subscriptions-container .note-message .icon {
  width: 25px;
  height: 25px;
}

.main-container .btn-container {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.main-container .btn-container .btn-sub-container {
  width: 150px;
  height: 40px;
}

.main-container .btn-container .btn-sub-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.main-container .btn-container .check-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: none;
}

.main-container .btn-container .check-loader.active {
  width: 20px;
  height: 20px;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.main-container .analytic-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.main-container .analytic-container .card-title-container {
  padding: 25px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.main-container .analytic-container .cards {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}

.main-container .main-footer {
  width: 100%;
  padding: 5px;
  margin-top: 50px;
  text-align: center;
  border-radius: 5px;
  /* background-color: white; */
  /* box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1); */
}

@media (max-width: 600px) {
  .main-container .welcome-container {
    flex-direction: column;
    gap: 25px;
  }

  .main-container .analytic-container .card-title-container {
    padding: 10px;
  }

  .main-container .analytic-container .cards {
    flex-direction: column;
  }

  .main-container .analytic-container .cards.users {
    flex-direction: column;
    height: 300px;
  }

  .main-container .analytic-container .cards.subscriptions {
    flex-direction: column;
    height: 300px;
  }

  .main-container .analytic-container .cards.sessions {
    flex-direction: column;
    height: 450px;
  }

  .main-container .analytic-container .cards.transactions {
    flex-direction: column;
    height: 450px;
  }

  .main-container .main-footer {
    font-size: 14px;
    width: 75%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {

}

@media (min-width: 901px) {

}

.current-admin-profile-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
}

.current-admin-profile-container .confirm-button-container {
  margin-top: 25px;
  width: 100%;
  height: 40px;
}

.current-admin-profile-container .permissions-container {
  background-color: white;
}

.edit-fullname-container,
.edit-username-container,
.edit-email-container,
.edit-password-container {
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  position: relative;
}

.edit-fullname-container .fullname-header-container,
.edit-username-container .username-header-container,
.edit-email-container .email-header-container,
.edit-password-container .password-header-container {
  width: 100%;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-fullname-container .fullname-header-label,
.edit-username-container .username-header-label,
.edit-email-container .email-header-label,
.edit-password-container .password-header-label {
  font-size: 18px;
  font-weight: bold;
  color: #444444;
}

.edit-fullname-container .fullname-header-icon-container,
.edit-username-container .username-header-icon-container,
.edit-email-container .email-header-icon-container,
.edit-password-container .password-header-icon-container {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-fullname-container .fullname-header-icon,
.edit-username-container .username-header-icon,
.edit-email-container .email-header-icon,
.edit-password-container .password-header-icon {
  font-size: 24px;
  color: #444444;
  cursor: pointer;
  transition: all 0.1s ease;
}

.edit-fullname-container .fullname-header-icon:hover,
.edit-username-container .username-header-icon:hover,
.edit-email-container .email-header-icon:hover,
.edit-password-container .password-header-icon:hover {
  color: black;
}

.edit-fullname-container .edit-profile-body-container,
.edit-username-container .edit-profile-body-container,
.edit-email-container .edit-profile-body-container,
.edit-password-container .edit-profile-body-container {
  width: 100%;
  padding: 10px 10px 0 10px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.edit-fullname-container .edit-profile-body-container.opened,
.edit-username-container .edit-profile-body-container.opened,
.edit-email-container .edit-profile-body-container.opened,
.edit-password-container .edit-profile-body-container.opened {
  display: flex;
}

.edit-email-container .edit-email-inputs-container,
.edit-password-container .edit-password-inputs-container {
  gap: 10px;
}

.edit-email-container .email-input-container,
.edit-email-container .code-input-container,
.edit-password-container .current-password-input-container,
.edit-password-container .new-password-input-container {
  width: 100%;
  display: none;
}

.edit-email-container .email-input-container.visible,
.edit-email-container .code-input-container.visible,
.edit-password-container .current-password-input-container.visible,
.edit-password-container .new-password-input-container.visible {
  display: flex;
}

.edit-password-container .new-password-input-container {
  display: none;
  flex-direction: column;
  gap: 10px;
}

.edit-email-container .email-previous,
.edit-password-container .password-previous {
  color: var(--danger);
  background-color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 10px 20px;
  display: none;
}

.edit-email-container .email-previous:hover,
.edit-password-container .password-previous:hover {
  color: white;
  background-color: var(--danger);
}

.edit-email-container .email-previous.visible,
.edit-password-container .password-previous.visible {
  display: block;
}

.edit-fullname-container .fullname-done-container,
.edit-username-container .username-done-container,
.edit-email-container .email-done-container,
.edit-password-container .password-done-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.edit-fullname-container .fullname-done-container,
.edit-username-container .username-done-container {
  margin-top: 10px;
}

.edit-fullname-container .fullname-done,
.edit-username-container .username-done,
.edit-email-container .email-done,
.edit-password-container .password-done {
  color: var(--primary);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 10px 20px;
}

.edit-fullname-container .fullname-done:hover,
.edit-username-container .username-done:hover,
.edit-email-container .email-done:hover,
.edit-password-container .password-done:hover {
  color: white;
  background-color: var(--primary);
}

.edit-fullname-container .fullname-done.loading,
.edit-username-container .username-done.loading,
.edit-email-container .email-done.loading,
.edit-password-container .password-done.loading {
  opacity: 0.5;
  pointer-events: none;
}

.edit-fullname-container .set-fullname-loader,
.edit-username-container .set-username-loader,
.edit-email-container .set-email-loader,
.edit-password-container .set-password-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: none;
}

.edit-fullname-container .set-fullname-loader.active,
.edit-username-container .set-username-loader.active,
.edit-email-container .set-email-loader.active,
.edit-password-container .set-password-loader.active {
  width: 20px;
  height: 20px;
  display: flex;
}

.image-input-container .image-input {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.base-info-card-component {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: white;
}

.base-info-card-component .img-container {
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.img-container .profile-image {
  width: 90%;
  height: 90%;
  border-radius: 30px;
  object-fit: contain;
}

.img-container .profile-initials {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 44px;
  font-weight: bold;
  color: white;
  background-color: var(--primary);
}

.base-info-card-component .info-container {
  width: calc(100% - 250px);
  height: 100%;
  padding: 25px 10px 10px 25px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.info-container .info-1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 4px;
  margin-top: 20px;
}

.info-1 .info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.info-1 .fullname-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-container .admin-role {
  color: gray;
  font-size: 14px;
  font-weight: 400;
}

.info-container .username {
  font-size: 16px;
  font-weight: 400;
}

.info-container .email {
  font-size: 16px;
  font-weight: 400;
}

.user-status .status-message.deleted {
  color: var(--danger);
  font-size: 24px;
}

.user-status .status-message.in-active {
  color: var(--danger);
  font-size: 24px;
}

.user-status .status-message.active {
  color: var(--success);
  font-size: 24px;
}

.info-container .info-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}

.info-2 > p{
  font-weight: 300;
  font-size: 14px;
}

.info-2 .registration-date {
  font-weight: bold;
}

@media (max-width: 900px) {
  .base-info-card-component {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    background-color: white;
  }
  
  .base-info-card-component .img-container {
    width: 250px;
    height: 250px;
    margin: 5px auto;
  }
  
  .img-container .profile-image {
    width: 95%;
    height: 95%;
    border-radius: 50%;
    border: 2px solid var(--primary);
    background-color: rgb(232, 231, 231);
  }
  
  .img-container .profile-initials {
    border-radius: 50%;
  }

  .base-info-card-component .info-container {
    width: 100%;
    gap: 30px;
  }
  
  .info-container .info-1 {
    width: 100%;
  }

  .info-2 > p{
    font-size: 12px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {

}

@media (min-width: 901px) {

}
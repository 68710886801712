.profile-dropdown-container {
  width: 200px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  position: absolute;
  top: 100%;
  right: 2%;
  overflow: hidden;
  padding: 10px;
  list-style: none;
}

.profile-dropdown-container .item-container {
  width: 100%;
}

.profile-dropdown-container.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s;
}

.profile-dropdown-container.in-active {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s;
}

.profile-dropdown-container .profile-user-info {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.profile-user-info .profile-fullname {
  width: 100%;
  color: var(--primary);
  text-transform: capitalize;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.profile-user-info .profile-email {
  width: 90%;
  overflow: hidden;
  text-wrap: nowrap;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.profile-dropdown-item-icon {
  font-size: 24px;
}

@media (max-width: 600px) {
  .profile-dropdown-container .profile-user-info {
    height: 80px;
  }
}
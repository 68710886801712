.new-pay-page {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-pay-page .input-group {
  width: 80%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.new-pay-page .input-group .input-container {
  width: 100%;
}

.new-pay-page .input-group .btn-container {
  width: 50%;
  height: 40px;
  margin-top: 10px;
}

.new-pay-page .input-container .textarea-label {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.new-pay-page .input-container .textarea-label span {
  color: red;
}

.new-pay-page .input-container .textarea-input {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Arial", sans-serif;
  color: #333;
  resize: none;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.new-pay-page .input-container .textarea-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

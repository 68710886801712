.error-container {
  width: 100vw;
  height: 100vh;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-primary-title {
  font-size: 88px;
}

.error-secondary-title {
  font-size: 44px;
}

.error-description {
  font-size: 22px;
  font-weight: 400;
  margin: 10px;
}

.error-container .button-container {
  width: 200px;
  height: 45px;
}

@media (max-width: 600px) {
  .error-primary-title {
    font-size: 68px;
  }
  
  .error-secondary-title {
    font-size: 34px;
  }
  
  .error-description {
    font-size: 16px;
    font-weight: 400;
    margin: 10px;
  }
  
  .error-container .button-container {
    width: 170px;
    height: 45px;
  }
  
}
.sidebar-container {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 260px;
  z-index: 998;
  background-color: green;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
}

@media (max-width: 600px) {
  .sidebar-container {
    position: absolute;
    width: 0;
    top: 50px;
    padding: 0;
  }

  .toggle-sidebar .sidebar-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }
}

@media (min-width: 601px) {
  .toggle-sidebar .sidebar-container {
    left: -260px;
  }
}

.sidebar-container::-webkit-scrollbar {
  width: 2px;
  height: 8px;
  background-color: transparent;
}

.sidebar-container::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

.sidebar-menu {
  list-style: none;
}

.temp {
  color: #4154f1;
  color: #012970;
  color: #899bbd;
  color: #f6f9ff;
}
.contained-button-component {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}

.contained-button-input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: var(--primary);
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contained-button-input:hover {
  background-color: var(--second);
}

.contained-button-input:active {
  background-color: var(--primary);
}

.contained-button-component .contained-button-input.danger {
  background-color: var(--danger);
}

.contained-button-component .contained-button-input.danger:hover {
  background-color: var(--bgDanger);
}
.lessons-body-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  margin: 10px;
}

.lessons-body-component .lesson-card-container {
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .lessons-body-component {
    padding: 10px 5px;
  }

  .lessons-body-component .lesson-card-container {
    margin-bottom: 5px;
  }
}
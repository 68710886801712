.addition-card-component {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.addition-card-component:hover {
  background-color: var(--bgColor);
}

.add-icon {
  font-size: 66px;
}
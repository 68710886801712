/* Modal.css */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  padding: 16px;
  border-radius: 15px;
  border: 2px solid var(--primary);
  background-color: var(--background-paper, #fff);
  box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%);
}

@media (max-width: 600px) {
  .modal-box {
    width: 80%;
  }
}
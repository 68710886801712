.analytical-card-component {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 22px;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.analytical-card-component.active {
  background-color: var(--bgColor);
}

.analytical-card-component:hover {
  background-color: var(--bgColor);
}

.icon-value-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 15px;
}

.anayltical-card-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;

}

.analytical-card-icon {
  font-size: 30px;
  color: white;
}

.analytical-card-title {
  font-size: 18px;
  color: gray;
  /* padding: 5px; */
  /* background-color: rebeccapurple; */
}
.updates-header-component {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.updates-header-component .scroll-container {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  position: absolute;
}

.updates-header-component .scroll-container.left {
  left: 5px;
}

.updates-header-component .scroll-container.right {
  right: 5px;
}

.updates-header-component .scroll-container .icon {
  color: #444;
  width: 30px;
  height: 30px;
  font-size: 14px;
  border-radius: 50%;
  transition: all 0.2s ease;
  cursor: pointer;
  transition: all 0.2s ease;
}

.updates-header-component .scroll-container .icon:hover {
  color: white;
  background-color: var(--primary);
}

.updates-header-component .items-container {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
  overflow-x: scroll; /* تفعيل التمرير الأفقي */
  overflow-y: hidden; /* إخفاء التمرير العمودي */
  -ms-overflow-style: none; /* إخفاء شريط التمرير في Internet Explorer و Edge */
  scrollbar-width: none; /* إخفاء شريط التمرير في Firefox */
}

.updates-header-component .items-container::-webkit-scrollbar {
  display: none; /* إخفاء شريط التمرير في Chrome و Safari */
}

.updates-header-component .item-container {
  flex: 0 0 23%;
  height: 100%;
}

@media (max-width: 600px) {
  .updates-header-component .scroll-container {
    display: none;
  }

  .updates-header-component .item-container {
    flex: 0 0 80%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .updates-header-component .item-container {
    flex: 0 0 60%;
  }
}
.password-verification-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.password-verification-title {
  font-size: 32px;
  color: black;
  padding-bottom: 10px;
}

.password-verification-message {
  width: 100%;
  padding: 10px 0;
}

.password-verification-inputs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.password-verification-buttons-container {
  width: 100%;
  height: 40px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.password-verification-Previous-button {
  width: 25%;
  height: 100%;
}

.password-verification-change-button {
  width: 40%;
  height: 100%;
}
.transactions-header-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.transactions-header-component .item {
  width: 35%;
  height: 100%;
}

@media (max-width: 600px) {
  .transactions-header-component .item {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .transactions-header-component .item {
    width: 50%;
    height: 100%;
  }
}

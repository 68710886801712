.message-box-component {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 10000;
}

.message-box-component.visable {
  display: flex;
}

.message-box-content {
  width: 50%;
  /* height: 50%; */
  border: 2px solid var(--primary);
  border-radius: 5px;
  padding: 15px;
  background-color: white;
}

.message-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message-box-title {
  font-size: 30px;
  font-weight: bold;
}

.message-box-header .close {
  color: var(--danger);
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 24px;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
}

.message-box-header .close:hover {
  background-color: var(--bgDanger);
}

.message-box-body {
  font-size: 18px;
}

.message-box-btns-container {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

@media (max-width: 600px) {
  .message-box-content {
    width: 80%;
  }

  .message-box-title {
    font-size: 20px;
  }
  
  .message-box-header .close {
    font-size: 16px;
    width: 35px;
    height: 35px;
  }

  .message-box-body {
    font-size: 14px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .message-box-content {
    width: 80%;
  }

  .message-box-title {
    font-size: 24px;
  }
  
  .message-box-header .close {
    font-size: 16px;
    width: 35px;
    height: 35px;
  }

  .message-box-body {
    font-size: 16px;
  }
}

@media (min-width: 901px) {
  .message-box-content {
    width: 60%;
  }

  .message-box-title {
    font-size: 24;
  }
  
  .message-box-header .close {
    font-size: 16px;
    width: 35px;
    height: 35px;
  }

  .message-box-body {
    font-size: 18px;
  }
}

@media (min-width: 1100px) {
  .message-box-content {
    width: 60%;
  }

  .message-box-title {
    font-size: 24;
  }
  
  .message-box-header .close {
    font-size: 16px;
    width: 35px;
    height: 35px;
  }

  .message-box-body {
    font-size: 18px;
  }
}

@media (min-width: 1500px) {
  .message-box-content {
    width: 60%;
  }

  .message-box-title {
    font-size: 24;
  }
  
  .message-box-header .close {
    font-size: 16px;
    width: 35px;
    height: 35px;
  }

  .message-box-body {
    font-size: 18px;
  }
}
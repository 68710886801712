.admin-profile-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  background-color: var(--bgColor);
}

.admin-profile-container .confirm-button-container {
  width: 100%;
  height: 40px;
  margin-top: 25px;
}

.admin-profile-sub-container {
  width: 80%;
  display: grid;
  grid-template-columns: calc(70% - 5px) calc(30% - 5px);
  grid-template-rows: 100%;
  gap: 10px;
  padding: 10px 0;
}

.admin-profile-sub-container .left-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.admin-profile-sub-container .right-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.actions-container,
.communication-container,
.transactions-container,
.history-container {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  position: relative;
}

.actions-container .actions-sub-container.loading {
  opacity: 0.4;
  pointer-events: none;
}

.actions-container .actions-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
}

.actions-container .actions-loader.active {
  width: 30px;
  height: 30px;
  display: block;
}

.actions-header,
.communication-header,
.transactions-header,
.history-header {
  font-size: 18px;
  font-weight: bold;
  color: #444444;
  margin-bottom: 10px;
}

.actions-container .actions-item,
.communication-container .communication-item,
.transactions-container .transactions-item,
.history-container .history-item {
  color: var(--primary);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  font-weight: bold;
}

.actions-container .delete-item {
  color: var(--danger);
}

.actions-container .actions-item.desabled {
  opacity: 0.4;
  pointer-events: none;
}

.actions-container .actions-item:hover,
.communication-container .communication-item:hover,
.transactions-container .transactions-item:hover,
.history-container .history-item:hover {
  background-color: var(--bgColor);
}

.left-side .base-info-container {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.left-side .description-container {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.left-side .permissions-container {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

@media (max-width: 900px) {
  .admin-profile-sub-container {
    width: 100%;
    padding: 10px;
    grid-template-columns: calc(100%);
    grid-template-rows: auto;
  }
}

@media (min-width: 601px) and (max-width: 900px) {

}

@media (min-width: 901px) {

}
.new-admin-component {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.423);
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5000;
}

.new-admin-component.opened {
  display: flex;
}

.new-admin-component .new-admin-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  gap: 10px;
  padding: 30px 20px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  position: relative;
}

.new-admin-component .new-admin-header {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.new-admin-header h3 {
  font-size: 24px;
  font-weight: bold;
}

.new-admin-header .save-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: none;
}

.new-admin-header .save-loader.active {
  width: 20px;
  height: 20px;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.new-admin-component .new-admin-message {
  width: 100%;
  padding: 0 5px;
  border-radius: 10px;
}

.new-admin-component .new-admin-body {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.new-admin-body.loading,
.new-admin-footer.loading {
  opacity: 0.5;
  pointer-events: none;
}

.new-admin-body .new-admin-input-container.fullname,
.new-admin-body .new-admin-input-container.username,
.new-admin-input-container.email .new-admin-email-input,
.new-admin-input-container.email .new-admin-verification-code-input,
.new-admin-input-container.password .new-admin-password-input,
.new-admin-input-container.password .new-admin-confirm-password-input {
  width: calc(50% - 5px);
}

.new-admin-body .new-admin-input-container.email,
.new-admin-body .new-admin-input-container.password {
  width: 100%;
  display: flex;
  gap: 10px;
}

.new-admin-body .new-admin-input-container.disable,
.new-admin-body .new-admin-email-input.disable,
.new-admin-body .new-admin-verification-code-input.disable,
.new-admin-body .new-admin-password-input.disable,
.new-admin-body .new-admin-confirm-password-input.disable {
  pointer-events: none;
  opacity: 0.7;
  
}

.new-admin-component .new-admin-footer {
  width: 100%;
  height: 50px;
  padding: 0 25px;
  position: absolute;
  bottom: 20px;
  left: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: end;
  gap: 15px;
}

.new-admin-add-button-container {
  width: 150px;
  height: 90%;
}

.new-admin-cancel-button-container {
  width: 100px;
  height: 90%;
}


@media (max-width: 900px) {
  .new-admin-component .new-admin-content {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    align-items: center;
    justify-content: start;
    padding-top: 0;
    position: fixed;
    overflow: auto;
  }

  .new-admin-component .new-admin-header {
    margin: 10px 0 25px;
  }
  
  .new-admin-component .new-admin-body {
    margin-bottom: 25px;
  }

  .new-admin-body .new-admin-input-container.fullname,
  .new-admin-body .new-admin-input-container.username,
  .new-admin-input-container.email .new-admin-email-input,
  .new-admin-input-container.email .new-admin-verification-code-input,
  .new-admin-input-container.password .new-admin-password-input,
  .new-admin-input-container.password .new-admin-confirm-password-input {
    width: calc(100%);
  }

  .new-admin-body .new-admin-input-container.email,
  .new-admin-body .new-admin-input-container.password {
    flex-direction: column;
  }

  .new-admin-component .new-admin-footer {
    position: relative;
    height: 50px;
  }

  .new-admin-add-button-container {
    width: 150px;
    height: 50px;
  }
  
  .new-admin-cancel-button-container {
    width: 100px;
    height: 50px;
  }
}
.branches-header-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.branches-header-component .item-container {
  width: 23%;
  height: 100%;
}

.branches-header-component .item-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 600px) {
  .branches-header-component {
    flex-direction: column;
    gap: 5px;
  }

  .branches-header-component .item-container {
    width: 100%;
    height: 150px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .branches-header-component .item-container {
    width: 50%;
    height: 180px;
  }
}
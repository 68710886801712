.logo-container {
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  padding: 0 10px 0 0;
  /* background-color: gray; */
}

.logo-container .icon {
  font-size: 32px;
  padding: 3px;
  border-radius: 50px;
}

.logo-container h2,
.logo-container .icon {
  color: var(--primary);
  cursor: pointer;
  transition: all 0.3s;
}

.logo-container h2 {
  font-size: 22px;
}

.logo-container h2:hover {
  color: var(--second);
}

.logo-container .icon:hover {
  color: white;
  background-color: var(--primary);
}

@media (max-width: 600px) {
  .logo-container {
    width: auto;
    margin-left: 10px;
  }

  .logo-container h2 {
    font-size: 16px;
  }
}
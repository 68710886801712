.lesson-header-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 5px;
  direction: rtl;
  padding: 10px;
}

.lesson-header-component .section-img-container {
  width: 350px;
  height: 250px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 5px;
  margin-top: 5px;
}

.lesson-header-component .section-img-container .cover-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 5px;
}

.lesson-header-component .section-img-container .no-cover {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
}

.lesson-header-component .section-info-container {
  width: calc(100% - 350px);
  height: 100%;
  padding: 25px 25px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.lesson-header-component .section-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lesson-header-component .section-info .label {
  font-size: 16px;
}

.lesson-header-component .section-info .label.link {
  text-decoration: underline;
}

.lesson-header-component .section-info-container .section-name {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.lesson-header-component .section-info-container .course-title {
  font-size: 14px;
}

.lesson-header-component .section-info-container .lessons-count-container,
.lesson-header-component .section-info-container .open-url-container {
  direction: ltr;
  text-align: left;
  padding-left: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.lesson-header-component .section-info-container .lessons-count-container {
  margin-top: 25px;
}

.lesson-header-component .section-info-container .lessons-count-container .video-icon,
.lesson-header-component .section-info-container .open-url-container .youtube-icon {
  font-size: 26px;
}

.lesson-header-component .lesson-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 25px;
}

.lesson-header-component .section-info-container .lesson-footer-container .addition-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.lesson-header-component .section-info-container .lesson-footer-container .addition-card-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.lesson-header-component .section-info-container .lesson-footer-container .addition-card-container .add-icon {
  font-size: 44px;
}

@media (min-width: 601px) and (max-width: 900px) {
  .lesson-header-component .section-img-container {
    height: 350px;
  }
}

@media (max-width: 900px) {
  .lesson-header-component {
    flex-direction: column;
  }

  .lesson-header-component .section-img-container {
    width: 100%;
  }

  .lesson-header-component .section-info-container {
    width: 100%;
    padding: 10px;
  }
  
  .lesson-header-component .section-info {
    width: 100%;
  }

  .lesson-header-component .section-info-container .section-name,
  .lesson-header-component .section-info-container .course-title {
    font-size: 14px;
  }
}
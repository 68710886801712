.profile-dropdown-item-container {
  width: 100%;
  height: 36px;
  background-color: transparent;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  transition: all 0.2s;
  padding-left: 20px;
  font-size: 14px;
}

.profile-dropdown-item-container:hover {
  color: var(--primary);
  background-color: #f6f9ff;
  border-radius: 3px;
}

.border-top {
  border-top: 1px solid rgba(234, 234, 234, 0.396);
}

.border-bottom {
  border-bottom: 1px solid rgba(234, 234, 234, 0.396);
}
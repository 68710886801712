.text-button-component {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}

.text-button-input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  color: var(--primary);
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.text-button-input:hover {
  color: var(--second);
}

.text-button-input:active {
  color: var(--primary);
}
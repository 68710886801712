.search-input-component {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.search-input {
  width: 100%;
  color: black;
  padding: 3px 7px;
  border: 2px solid #B0BEC5;
  font-size: 16px;
  font-weight: bold;
  border-radius: 3px;
  outline-color: var(--primary);
  padding-left: 30px;
}

.search-icon {
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.clear-icon {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

}

.clear-icon:hover {
  transition: all 0.2s ease;
  color: var(--danger)
}

@media (max-width: 600px) {
  .search-input {
    font-size: 14px;
  }
}
.user-details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  margin: 25px 0;
}

.user-details-sub-container {
  width: 70%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.user-details-sub-container .user-info {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: start;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  padding: 20px;
}

.user-details-sub-container .user-details-profile {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 25px;
}

.user-details-profile .profile-image-container {
  background-color: rgb(232, 231, 231);
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.user-details-profile .profile-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.user-details-profile .profile-initials {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-details-profile .user-info-1 h1 {
  font-size: 24px;
  margin: 0;
}

.user-details-profile .user-info-1 .item-container {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.item-container .username,
.item-container .email {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  overflow: auto;
  scrollbar-width: none;
}

.user-details-profile .user-info-1 p {
  margin: 3px 0;
}

.user-details-sub-container .user-info-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.user-info-2 .user-info-item {
  border-bottom: 1px solid #4444;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.user-info-2 .user-info-item p {
  width: 50%;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
}

.user-details-sub-container .sessions-analytic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.sessions-analytic-container .analytic-container-title {
  width: 100%;
}

.sessions-analytic-container .analytic-container-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.sessions-analytic-container .analytic-item {
  width: calc(100% / 3.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.2s ease;
  cursor: default;
}

.sessions-analytic-container .analytic-item:hover {
  background-color: var(--bgColor);
}

.sessions-analytic-container .analytic-item h4 {
  margin: 0;
  font-size: 18px;
}

.sessions-analytic-container .analytic-item p {
  text-align: center;
  font-weight: bold;
}

.user-details-sub-container .subscription-container,
.user-details-sub-container .payments-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.user-details-sub-container .subscription-container .subscription-title,
.user-details-sub-container .payments-container .payments-title {
  width: 100%;
}

.user-details-sub-container .subscription-container .subscription-body,
.user-details-sub-container .payments-container .payments-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.subscription-body .subscription-table,
.payments-body .payments-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.subscription-body .subscription-header th,
.payments-body .payments-header th {
  width: calc(100% / 9);
  padding: 10px 0;
  background-color: var(--primary);
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.subscription-body .subscription-item td,
.payments-body .payments-item td {
  width: calc(100% / 9);
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.payments-body .payments-item td {
  width: calc(100% / 7);
}

.subscription-body .subscription-item td p {
  display: inline;
  color: white;
  padding: 3px 8px;
  border-radius: 3px;
}

.subscription-body .subscription-item td .active {
  background-color: var(--success);
}

.subscription-body .subscription-item td .in-active {
  background-color: var(--danger);
}

.subscription-body .subscription-action {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  background-color: rgb(11, 194, 11);
}

@media (max-width: 600px) {
  .subscription-body .subscription-header th,
  .payments-body .payments-header th {
    font-size: 12px;
  }

  .subscription-body .subscription-item td,
  .payments-body .payments-item td {
    font-size: 10px;
  }
}

@media (max-width: 900px) {
  .user-details-container {
    margin: 0;
  }

  .user-details-sub-container {
    width: 100%;
    border-radius: 0;
    padding: 10px 10px 25px;
    gap: 10px;
  }

  .user-details-sub-container .user-details-profile {
    flex-direction: column;
  }

  .user-details-profile .profile-image-container {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid var(--primary);
    background-color: rgb(232, 231, 231);
  }
  
  .user-details-profile .user-info-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  .user-details-profile .user-info-1 h1 {
    text-align: center;
    width: 100%;
  }
  
  .user-details-profile .user-info-1 .item-container {
    width: 100%;
    justify-content: center;
  }

  .item-container .username,
  .item-container .email {
    width: 100%;
  }

  .user-details-sub-container .sessions-analytic-container {
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
  }

  .sessions-analytic-container .analytic-container-body {
    flex-direction: column;
  }

  .sessions-analytic-container .analytic-item {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .sessions-analytic-container .analytic-item h4 {
    font-size: 16px;
  }

  .subscription-body .subscription-header th,
  .payments-body .payments-header th {
    padding: 5px 0;
  }

  .user-details-sub-container .subscription-container,
  .user-details-sub-container .payments-container {
    gap: 10px;
    padding: 10px;
  }

  .subscription-body .subscription-item td,
  .payments-body .payments-item td {
    padding: 5px 0;
  }
}

.rates-body-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 25px;
  position: relative;
}

.rates-body-component .rate-card-container {
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .rates-body-component {
    padding: 10px 5px;
  }
  
  .rates-body-component .rate-card-container {
    margin-bottom: 5px;
  }
}
.errors-body-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 25px;
  position: relative;
}


.errors-body-component .error-card-container {
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .errors-body-component  {
    padding: 10px 5px;
  }

  .errors-body-component .error-card-container {
    margin-bottom: 5px;
  }
}
.users-body-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 25px;
  background-color: var(--bgColor);
  position: relative;
}

.users-table-container {
  width: 90%;
  background-color: white;
  padding: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 -15px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  text-align: left;
}

.users-table th {
  background-color: var(--primary);
  color: white;
  padding: 10px;
  font-weight: bold;
  padding-top: 15px;
}

.users-table .first-column {
  border-top-left-radius: 15px;
}

.users-table .last-column {
  border-top-right-radius: 15px;
}

.users-table tr {
  border-bottom: 1px solid #e0e0e069;
}

.users-table td {
  padding: 10px;
}

.users-table td,
.users-table th {
  white-space: nowrap;
}

.user-name {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name span {
  font-weight: bold;
}

.user-avatar-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 10px;
  pointer-events: none;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
}

.status.active {
  background-color: var(--success);
}

.status.deleted {
  background-color: var(--danger);
}

.users-table tr {
  transition: all 0.3s ease;
}

.users-table tr:hover {
  background-color: #f1f1f1;
}

.users-table tr .no-users-available {
  text-align: center;
  font-weight: bold;
  padding: 25px 0;
}

.actions {
  text-align: center;
  position: relative;
}

.actions-dropdown-container {
  position: relative;
  right: 100%;
  transform: translateX(40%);
}

.header-actions {
  text-align: center;
}

.more-options-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 32px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.more-options-btn:hover {
  transition: all 0.2s ease;
  color: white;
  background-color: var(--primary);
}

.actions-dropdown-container .drop-down-item-link {
  width: 100%;
}

@media (max-width: 600px) {
  .users-table-container {
    width: 100%;
  }

  .users-body-component {
    padding: 15px;
  }

  .users-table {
    font-size: 14px;
  }

  .users-table th, .users-table td {
    padding: 8px;
  }

  .users-table .user-avatar {
    width: 30px;
    height: 30px;
  }

  .user-avatar-placeholder {
    width: 30px;
    height: 30px;
  }

  .users-table .status {
    font-size: 12px;
    padding: 3px 6px;
  }

  .more-options-btn {
    font-size: 24px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .users-table-container {
    width: 100%;
  }
  
  .users-table {
    font-size: 15px;
  }

  .users-table th, .users-table td {
    padding: 10px;
  }

  .users-table .user-avatar {
    width: 35px;
    height: 35px;
  }

  .user-avatar-placeholder {
    width: 35px;
    height: 35px;
  }

  .users-table .status {
    font-size: 13px;
    padding: 4px 8px;
  }

  .more-options-btn {
    font-size: 28px;
  }
}
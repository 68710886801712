.lessons-main-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
}

.lessons-main-component .lessons-confirm-btn-container {
  width: 100%;
  height: 40px;
  margin-top: 25px;
}

.lessons-main-component .loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: none;
}

.lessons-main-component .loading-container.active {
  display: flex;
}

.lessons-main-component .add-update-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
}

.lessons-main-component .add-update-container.in-active {
  display: none;
}

.lessons-main-component .lessons-header {
  width: 100%;
  background-color: transparent;
}

.lessons-header .lessons-header-sub-container {
  margin: 10px;
  min-height: 300px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.lessons-main-component .lessons-body {
  width: 100%;
  flex: 1 1 auto;
}

@media (max-width: 600px) {
  .lessons-header .lessons-header-sub-container {
    height: auto;
  }

  .lessons-main-component .lessons-body {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .lessons-header .lessons-header-sub-container {
    height: auto;
  }
  
  .lessons-main-component .lessons-body {
    width: 100%;
    height: auto;
  }
}
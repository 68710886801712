.loader-container {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  color: var(--primary);
  font-family: 'Arial', sans-serif;
  text-align: center;
  position: absolute;
  z-index: 5000;
}

.loader-container.visible {
  display: flex;
}

.dot-loader {
  display: flex;
  gap: 8px;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: var(--primary);
  border-radius: 50%;
  animation: dot-blink 1.2s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
}
.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.loading-text {
  margin-top: 15px;
  font-size: 16px;
  font-family: Nfont;
  /* font-weight: bold; */
  color: var(--primary);
}

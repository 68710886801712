.course-card-component {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.course-card-component .course-card-header-container {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  z-index: 10;
}

.course-card-component .more-options-container {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.course-card-component .more-options-container .more-icon {
  cursor: pointer;
  transition: all 0.2s ease;
}

.course-card-component .more-options-container .more-icon:hover {
  color: #B0BEC5;
}

.course-card-component .more-options-container .history {
  width: 100%;
}

.course-card-component .more-options-container .history.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.course-card-component .more-options-container .input-image-container {
  width: 100%;
}

.course-card-component .more-options-container .image-input {
  display: none;
}

.course-card-component .course-card-img-container,
.course-card-component .cover-image-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.course-card-component .course-card-img-container .cover-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.course-card-component .course-card-img-container .no-cover {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
}

.course-card-component .course-card-body-container {
  width: 100%;
  height: 40%;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
  position: relative;
}

.course-card-component .course-title-container .course-title {
  padding: 3px 10px;
  direction: rtl;
  font-weight: bold;
  font-size: 13px;
  max-height: 65px;
  overflow: hidden;
  margin: 5px 0;
}

.course-card-component .course-card-body-container .course-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.course-footer-container .course-status {
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}

.course-footer-container .course-status.active {
  background-color: var(--success);
}

.course-footer-container .course-status.in-active {
  background-color: var(--danger);
}

.course-footer-container .teacher-name,
.course-footer-container .branch-name {
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  color: black;
}

@media (max-width: 600px) {

}

@media (min-width: 601px) and (max-width: 900px) {
  
}
.general-questions-add-update-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.192);
}

.general-questions-add-update-component .general-question-add-update-content {
  width: 50%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border-radius: 15px;
  gap: 10px;
  padding: 30px 20px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  position: relative;
  overflow-y: auto;
}

.general-questions-add-update-component .new-question-header {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.general-questions-add-update-component .new-question-header .save-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: none;
}

.general-questions-add-update-component .new-question-header .save-loader.active {
  width: 20px;
  height: 20px;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.general-questions-add-update-component .new-question-body {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.general-question-add-update-content .new-question-body.loading,
.general-question-add-update-content .new-question-footer.loading {
  opacity: 0.5;
  pointer-events: none;
}

.general-questions-add-update-component .new-question-message {
  width: 100%;
  padding: 0 5px;
  border-radius: 10px;
}

.general-questions-add-update-component .input-container {
  width: 100%;
  flex-direction: column;
}

.general-questions-add-update-component .input-container-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.general-questions-add-update-component .label {
  font-size: 14px;
  padding: 5px;
  font-weight: bold;
  display: flex;
  gap: 3px;
}

.general-question-add-update-content .label strong {
  color: var(--danger);
}

.general-questions-add-update-component .latters-counter {
  font-size: 12px;
  font-weight: bold;
}

.general-questions-add-update-component .question-input,
.general-questions-add-update-component .answer-input {
  font-weight: bold;
  width: 100%;
  min-height: 100px;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 10px;
  resize: none;
  background-color: transparent;
  border: 1px solid black;
}

.general-questions-add-update-component .answer-input {
  min-height: 250px;
}

.general-questions-add-update-component .new-question-footer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: end;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.general-question-add-update-content .new-question-add-button-container,
.general-question-add-update-content .new-question-cancel-button-container {
  width: 100px;
  height: 40px;
}

@media (max-width: 900px) {
  .general-questions-add-update-component {
    height: 100vh;
  }

  .general-questions-add-update-component .general-question-add-update-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@media (min-width: 601px) and (max-width: 900px) {

}

@media (min-width: 901px) {

}

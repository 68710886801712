.subscription-plan-card-component {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.subscription-plan-card-component .plan-header {
  direction: rtl;
  width: 100%;
  height: 75px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.subscription-plan-card-component .plan-header div {
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  font-size: 14px;
}

.subscription-plan-card-component .plan-header .index-container,
.subscription-plan-card-component .plan-header .icon-container {
  width: 10%;
  text-align: center;
  align-items: center;
}

.subscription-plan-card-component .plan-header .plan-name-container,
.subscription-plan-card-component .plan-header .branch-name-container {
  width: 30%;
}

.subscription-plan-card-component .plan-header .status-container {
  width: 20%;
  align-items: start;
  justify-content: center;
  direction: ltr;
}

.subscription-plan-card-component .plan-header .status-container .value {
  width: 100%;
  max-width: 150px;
  text-align: center;
  padding: 3px 6px;
  border-radius: 3px;
  color: white;
}

.subscription-plan-card-component .plan-header .status-container .value.active {
  background-color: var(--success);
}

.subscription-plan-card-component .plan-header .status-container .value.in-active {
  background-color: var(--danger);
}

.subscription-plan-card-component .plan-header .index {
  font-weight: bold;
}

.subscription-plan-card-component .plan-header div .value {
  font-weight: bold;
}

.subscription-plan-card-component .plan-header .card-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
}

.subscription-plan-card-component .plan-header .card-icon:active {
  transform: scale(1.2); 
}

.subscription-plan-card-component .plan-header .label {
  text-transform: uppercase;
  letter-spacing: 1px;
}







.subscription-plan-card-component .card-body {
  width: 100%;
  padding: 10px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 5px;
  position: relative;
}

.subscription-plan-card-component .card-body.opened {
  display: flex;
  border-bottom: 2px solid var(--primary);
}

.subscription-plan-card-component .card-body .item {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 5px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.subscription-plan-card-component .card-body .item.inline {
  flex-direction: row;
}

.subscription-plan-card-component .card-body .item .label {
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.subscription-plan-card-component .card-body .item .value {
  /* width: 100%;
  padding-left: 10px; */
  font-size: 14px;
  line-height: 1.6;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.subscription-plan-card-component .card-body .item .value.history {
  text-decoration: underline;
}

.subscription-plan-card-component .card-body .item.description .description-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.subscription-plan-card-component .card-body .item.description .description-icon {
  width: 25px;
  height: 25px;
  /* margin: 10px; */
  color: #444444;
  transition: all 0.2s ease;
  cursor: pointer;
}

.subscription-plan-card-component .card-body .item.description .description-icon:hover {
  color: black;
}

.subscription-plan-card-component .card-body .item.description .description-icon.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.subscription-plan-card-component .card-body .item.description .value {
  width: 100%;
  direction: rtl;
  padding: 0 20px 20px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.6;
  overflow-x: auto;
}

.subscription-plan-card-component .card-body .item.description .input-description-container {
  width: 100%;
}

.subscription-plan-card-component .card-body .item.description .input-description-container.hidden {
  display: none;
}

.subscription-plan-card-component .card-body .item.description .input-description-container .description-input {
  font-weight: bold;
  width: 100%;
  min-height: 100px;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 10px;
  resize: none;
  background-color: transparent;
  border: 1px solid black;
  direction: rtl;
}

.subscription-plan-card-component .card-body .item.description .description-btn-container {
  width: 25%;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.subscription-plan-card-component .card-body .item.description .description-btn {
  color: var(--primary);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 10px 20px;
}

.subscription-plan-card-component .card-body .item.description .description-btn.loading {
  opacity: 0.5;
  pointer-events: none;
}

.subscription-plan-card-component .card-body .item.description .description-btn:hover {
  color: white;
  background-color: var(--primary);
}

.subscription-plan-card-component .card-body .item.description .description-input-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: none;
}

.subscription-plan-card-component .card-body .item.description .description-input-loader.active {
  width: 20px;
  height: 20px;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.subscription-plan-card-component .card-body .item.action {
  align-items: center;
  height: 50px;
}

.subscription-plan-card-component .card-body .btn-container {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscription-plan-card-component .card-body .btn-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 600px) {
  .subscription-plan-card-component .plan-header {
    min-height: 110px;
  }

  .subscription-plan-card-component .plan-header div {
    height: 100%;
  }

  .subscription-plan-card-component .plan-header .status-container {
    justify-content: start;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .subscription-plan-card-component .plan-header div {
    height: 100%;
  }
}
.selector-component {
  width: 100%;
  height: 100%;
  padding: 3px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selector-title {
  white-space: nowrap;
}

.selector-title.no-title {
  display: none;
}

.selector-dropdown {
  flex: 1;
  min-width: 0;
  outline: none;
  padding: 5px;
  width: 100%;
  border: 2px solid #B0BEC5;
}

.selector-dropdown.rtl {
  direction: rtl;
}

@media (max-width: 600px) {
  .selector-component {
    flex-direction: column;
    align-items: start;
    gap: 3px;
  }
}
.courses-body-component {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  column-gap: 20px;
  width: 100%;
  padding: 25px;
  background-color: var(--bgColor);
  position: relative;
}

.courses-body-component .course-card-container {
  max-width: 350px;
  height: 300px;
}

@media (max-width: 600px) {
  .courses-body-component {
    grid-template-columns: repeat(1, 1fr);
  }

  .courses-body-component .course-card-container {
    max-width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .courses-body-component {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 901px) {
  .courses-body-component {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1500px) {
  .courses-body-component {
    grid-template-columns: repeat(4, 1fr);
  }
}
.sidebar-section-item {
  font-weight: bold;
  padding: 5px 3px;
  cursor: pointer;
  border-radius: 3px;
}

.sidebar-section-item.active {
  color: var(--second);
  background-color: #f6f9ff;
}

.sidebar-section-item:hover {
  background-color: #f6f9ff;
}


.sidebar-section-item-link {
  color: inherit;
}
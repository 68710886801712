.sidebar-section-container {
  list-style: none;
}

.sidebar-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 3px;
}

.sidebar-section-title:hover {
  background-color: #f6f9ff;
}

.sidebar-section-title.active {
  color: var(--primary);
  background-color: #f6f9ff;
}

.sidebar-section-title:hover {
  color: var(--primary);
}

.sidebar-section-title div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.section-items-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 60px;
  gap: 10px;
  list-style: circle;
  height: auto;
  transition: height 0.5s ease, opacity 0.5s ease;
  transform: translateY(0);
}

.section-items-container.in-active {
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  transform: translateY(-20px);
}

.in-active-section-arrow {
  transform: rotate(180deg);
}

.card-dropdown-item-component {
  width: 100%;
  padding: 5px;
  color: black;
  /* box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1); */
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  border-radius: 3px;
  gap: 10px;
  direction: ltr;
}

.card-dropdown-item-component.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.card-dropdown-item-component:hover {
  color: var(--primary);
  background-color: #f6f9ff;
  border-radius: 3px;
}

.card-dropdown-item-component p {
  overflow: hidden;
}

.card-dropdown-item-component.danger {
  color: var(--danger);
}
.lesson-card-component {
  width: 100%;
  height: 75px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.lesson-card-component .more-options-container {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.lesson-card-component .more-options-container .more-icon {
  cursor: pointer;
}

.lesson-card-component .more-options-container .history {
  width: 100%;
}

.lesson-card-component .lesson-info-container {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 2;
  align-items: center;
  justify-content: end;
  gap: 10px;
  overflow: hidden;
}

.lesson-card-component .lesson-info-container .index {
  font-weight: bold;
}

.lesson-card-component .lesson-info-container .lesson-title {
  text-wrap: nowrap;
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  direction: rtl;
}

@media (max-width: 600px) {

}

@media (min-width: 601px) and (max-width: 900px) {
  
}
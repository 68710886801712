.transactions-timeline-component {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 100px 0 0;
}

.transactions-timeline-component .line {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid var(--second);
}

.transactions-timeline-component .end-point-for-no-items {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-47%);
  background-color: var(--second);
  display: none;
}

.transactions-timeline-component .end-point-for-no-items.visible {
  display: flex;
}

.transactions-timeline-component .line::before {
  content: 'Start';
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  background-color: var(--primary);
  position: absolute;
  top: -10px;
  left: -20px;
  transform: translateX(-3%);
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.transactions-timeline-component .transactions-timeline-item-container {
  position: relative;
}

@media (max-width: 900px) {
  .transactions-timeline-component .line {
    left: 0%;
  }

  .transactions-timeline-component .end-point-for-no-items {
    left: 0;
  }
}

@media (min-width: 601px) and (max-width: 900px) {

}

@media (min-width: 901px) {

}

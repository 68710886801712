.dashboard-container {
  width: calc(100% -260px);
  min-height: 100%;
  margin-left: 260px;
  transition: all 0.3s ease;
  display: flex;
}

.toggle-sidebar .dashboard-container {
  width: 100%;
  margin-left: 0;
}

.dashboard-container .body {
  width: 100%;
  min-height: calc(100% - 60px);
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .dashboard-container {
    width: 100%;
    margin-left: 0;
  }
  .dashboard-container .body {
    min-height: calc(100% - 50px);
    margin-top: 50px;
  }
}
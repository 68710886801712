.operations-history-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
}

.operations-body-container .loading-container {
  width: 100vw;
  height: 100vh;
  display: none;
}

.operations-body-container .loading-container.active {
  display: flex;
}

.operations-history-page-container .operations-header-container {
  width: 100%;
  height: 150px;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.operations-history-page-container .operations-body-container {
  width: 100%;
  flex: 1 1 auto;
}
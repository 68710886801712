.error-card-component {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.error-card-component .card-header {
  width: 100%;
  height: 75px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.error-card-component .card-header .label-index,
.error-card-component .card-header .icon-container {
  width: 10%;
  text-align: center;
  align-items: center;
}

.error-card-component .card-header .label-layer,
.error-card-component .card-header .label-class {
  width: 30%;
}

.error-card-component .card-header .label-status {
  width: 100px;
}

.error-card-component .card-header .label-status .value {
  text-align: center;
}

.error-card-component .card-header div {
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  font-size: 14px;
}

.error-card-component .card-header .index {
  font-weight: bold;
}

.error-card-component .card-header div .value {
  font-weight: bold;
}

.error-card-component .card-header .error-status {
  background-color: #333;
  padding: 3px;
  border-radius: 3px;
  color: white;
}

.error-card-component .card-header .error-status.handled {
  background-color: var(--success);
}

.error-card-component .card-header .error-status.not-handled {
  background-color: var(--danger);
}

.error-card-component .card-header .card-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
}

.error-card-component .card-header .card-icon:active {
  transform: scale(1.2); 
}

.error-card-component .card-body {
  width: 100%;
  padding: 10px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 5px;
  position: relative;
}

.error-card-component .card-body.opened {
  display: flex;
  border-bottom: 2px solid var(--primary);
}

.error-card-component .card-body .item {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 5px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.error-card-component .card-body .item .label {
  font-weight: bold;
  font-size: 15px;
}

.error-card-component .card-body .item .value {
  width: 100%;
  padding-left: 10px;
  font-size: 14px;
}

.error-card-component .label {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.error-card-component .value {
  line-height: 1.6;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.error-card-component .card-body .date,
.error-card-component .card-body .error-code {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.error-card-component .card-body .date .label,
.error-card-component .card-body .date .value,
.error-card-component .card-body .error-code .label,
.error-card-component .card-body .error-code .value {
  width: auto;
}

.error-card-component .card-body .description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.error-card-component .card-body .description-header {
  width: 100%;
  display: flex;
}

.error-card-component .card-body .description-body {
  width: 100%;
  display: none;
  flex-direction: column;
  /* background-color: gray; */
}

.error-card-component .card-body .description-body.opened {
  display: flex;
}

.error-card-component .card-body .description-body .description-input {
  font-weight: bold;
  width: 100%;
  min-height: 100px;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 10px;
  resize: none;
  background-color: transparent;
  border: 1px solid black;
}

.error-card-component .card-body .description-body .description-btn-container {
  width: 25%;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.error-card-component .card-body .description-body .description-btn {
  color: var(--primary);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 10px 20px;
}

.error-card-component .card-body .description-body .description-btn.loading {
  opacity: 0.5;
  pointer-events: none;
}

.error-card-component .card-body .description-body .description-btn:hover {
  color: white;
  background-color: var(--primary);
}

.error-card-component .card-body .description-body .description-input-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: none;
}

.error-card-component .card-body .description-body .description-input-loader.active {
  width: 20px;
  height: 20px;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-card-component .card-body .description-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.error-card-component .card-body .description-icon {
  width: 25px;
  height: 25px;
  margin: 10px;
  color: #444444;
  transition: all 0.2s ease;
  cursor: pointer;
}

.error-card-component .card-body .description-icon:hover {
  color: black;
}

.error-card-component .card-body .btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.error-card-component .card-body .btns.hidden {
  display: none;
}

.error-card-component .card-body .btns .btn-container {
  width: 100%;
  height: 40px;
}

.error-card-component .json-container {
  width: 100%;
  font-size: 14px;
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 5px;
  color: #e8e8e8;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.6;
  overflow-x: auto;
}

.error-card-component .json-container p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-card-component .json-container p .icon.not-visible {
  display: none;
}

.error-card-component .item.error-history {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.error-card-component .item.error-history .label,
.error-card-component .item.error-history .value {
  width: auto;
}

.error-card-component .item.error-history .value {
  text-decoration: underline;
}


@media (max-width: 600px) {
  .error-card-component .card-header {
    min-height: 110px;
  }

  .error-card-component .card-header div {
    height: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .error-card-component .card-header div {
    height: 100%;
  }
}

@media (min-width: 901px) {

}
.text-input-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.text-input-title {
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-input-title span {
  display: none;
  font-size: 14px;
  color: var(--danger);
}

.text-input-title span.required-field {
  display: inline;
}

.text-input {
  width: 100%;
  color: black;
  padding: 10px 7px;
  border: 2px solid #B0BEC5;
  font-size: 16px;
  font-weight: bold;
  border-radius: 3px;
  outline-color: var(--primary);
}

.text-input.rtl {
  direction: rtl;
}

.error-label {
  color: var(--danger);
  font-size: 12px;
  font-weight: bold;
  padding-left: 5px;
}

@media (max-width: 600px) {
  .text-input-title {
    font-size: 12px;
    margin-bottom: 0;
  }

  .text-input {
    font-size: 14px;
  }
  
  .error-label {
    font-size: 10px;
  }
}
.users-search-bar-component {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.users-search-bar-component .users-selector-container {
  width: 30%;
}

@media (max-width: 600px) {
  .users-search-bar-component {
    padding: 5px;
    flex-direction: column;
    align-items: center;
  }

  .users-search-input-container {
    width: 100%;
    height: 40px;
  }

  .users-search-bar-component .users-selector-container {
    width: 100%;
    height: 70px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .users-search-bar-component {
    padding: 5px;
    flex-direction: column;
    align-items: center;
  }

  .users-search-input-container {
    width: 100%;
    height: 40px;
  }

  .users-search-bar-component .users-selector-container {
    width: 100%;
    height: 70px;
  }
}
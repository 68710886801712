.addition-component {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.addition-component .addition-header {
  width: 100%;
  height: 75px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.addition-component .addition-header .label-index,
.addition-component .addition-header .icon-container {
  width: 10%;
  text-align: center;
  align-items: center;
}

.addition-component .addition-header .label-table-name,
.addition-component .addition-header .label-user-name {
  width: 30%;
}

.addition-component .addition-header div {
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  font-size: 14px;
}

.addition-card-component .addition-header .index {
  font-weight: bold;
}

.addition-component .addition-header div .value {
  font-weight: bold;
}

.addition-component .addition-header .card-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
}

.addition-component .addition-header .card-icon:active {
  transform: scale(1.2); 
}

.addition-component .card-body {
  width: 100%;
  padding: 10px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 5px;
  position: relative;
}

.addition-component .card-body.opened {
  display: flex;
  border-bottom: 2px solid var(--primary);
}

.addition-component .card-body .item {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 5px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.addition-component .card-body .item .label {
  font-weight: bold;
  font-size: 15px;
}

.addition-component .card-body .item .value {
  width: 100%;
  padding-left: 10px;
  font-size: 14px;
}

.addition-component .label {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.addition-component .value {
  line-height: 1.6;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.addition-component .card-body .date{
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.addition-component .card-body .date .label,
.addition-component .card-body .date .value {
  width: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.addition-component .json-container {
  width: 100%;
  font-size: 14px;
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 5px;
  color: #e8e8e8;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.6;
  overflow-x: auto;
}

.addition-component .json-container p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addition-component .json-container p .icon.not-visible {
  display: none;
}

.addition-component .item.addition-history {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.addition-component .item.addition-history .label,
.addition-component .item.addition-history .value {
  width: auto;
}

.addition-component .item.addition-history .value {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .addition-component .addition-header {
    min-height: 110px;
  }

  .addition-component .addition-header div {
    height: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .addition-component .addition-header div {
    height: 100%;
  }
}

@media (min-width: 901px) {

}
.message-label-component {
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}

.message-label-component.success {
  background-color: var(--bgSuccess);
  border: 2px solid var(--success);
}

.message-label-component.info {
  background-color: var(--bgInfo);
  border: 2px solid var(--info);
}

.message-label-component.error {
  background-color: var(--bgDanger);
  border: 2px solid var(--danger);
}

.message-label-component.not-visible {
  display: none;
}

@media (max-width: 600px) {
  .message-label-component {
    padding: 5px;
    font-size: 14px;
  }
}
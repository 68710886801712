.permissions-denied-container {
  width: 100vw;
  height: 100vh;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.permissions-denied-primary-title {
  font-size: 88px;
}

.permissions-denied-secondary-title {
  font-size: 44px;
}

.permissions-denied-description {
  width: 75%;
  font-size: 22px;
  font-weight: 400;
  margin: 10px;
  text-align: center;
}

.permissions-denied-button-container {
  margin-top: 25px;
  width: 200px;
  height: 45px;
}

@media (max-width: 600px) {
  .permissions-denied-primary-title {
    font-size: 68px;
  }
  
  .permissions-denied-secondary-title {
    font-size: 34px;
  }
  
  .permissions-denied-description {
    width: 90%;
    font-size: 16px;
    font-weight: 400;
    margin: 10px;
  }

  .permissions-denied-button-container {
    width: 170px;
    height: 45px;
  }
  
}
.transactions-item-component {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: default;
}

.transactions-item-component.closed {
  visibility: hidden;
  height: 50px;
}

.transactions-item-component.left {
  justify-content: start;
}

.transactions-item-component.right {
  justify-content: end;
}

.transactions-item-component .transactions-stage-container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--second);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  visibility: visible;
  cursor: pointer;
}

.transactions-item-component .transactions-stage-container:hover {
  background-color: var(--primary);
}

.transactions-item-component .transactions-stage-container.left {
  right: 50%;
  transform: translateX(50%);
}

.transactions-item-component .transactions-stage-container.right {
  left: 50%;
  transform: translateX(-50%);
}

.transactions-item-component .transactions-stage-container .transactions-stage {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.transactions-item-component .transactions-stage-container.hovered {
  background-color: var(--primary);
}

.transactions-item-component .item-content {
  width: calc(50% - 50px);
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 5px;
  transition: all 0.3s ease;
  /* background-color: white; */
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: var(--bgColor);
}

.transactions-item-component.closed .item-content {
  display: none;
}

.transactions-item-component .item-content:hover {
  /* background-color: var(--bgColor); */
  background-color: white;
}

.transactions-item-component .item-content .item-icon-container {
  width: 35px;
  height: 35px;
  margin: 20px 0;
}

.item-content .item-icon-container .item-icon {
  width: 100%;
  height: 100%;
}

.transactions-item-component .item-content .item-message-container {
  width: 100%;
}

.transactions-item-component .item-content .item-data-container {
  width: 100%;
  padding: 20px;
  margin: 20px auto;
  border-radius: 10px;
  background-color: var(--bgColor);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.transactions-item-component .item-content .item-data {
  font-size: 14px;
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 5px;
  color: #e8e8e8;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.6;
  overflow-x: auto;
}

.transactions-item-component .item-content .item-view-history-container {
  width: 100%;
  font-size: 14px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transactions-item-component .item-content .item-view-history-container .item-history-value {
  padding: 2px;
  border-radius: 5px;
  background-color: var(--bgColor);
}

.transactions-item-component .item-content .item-view-history-container .item-history-value:hover {
  text-decoration: underline;
}

.transactions-item-component .item-content .item-date-container {
  width: 100%;
  font-size: 14px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transactions-item-component .item-content .item-date {
  padding: 2px;
  border-radius: 5px;
  background-color: var(--bgColor);
}

.transactions-item-component .item-content .item-data .item-data-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transactions-item-component .item-content .item-data .item-data-header .icon {
  cursor: pointer;
}

.transactions-item-component .item-content .item-data .item-data-header .icon:hover {
  color: antiquewhite;
}

.transactions-item-component .item-content .item-message {
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
  padding: 0 5px;
  text-align: left;
}

.transactions-item-component .item-message strong {
  font-weight: bold;
}

@media (max-width: 900px) {
  .transactions-item-component {
    margin-left: 23px;
  }

  .transactions-item-component.left,
  .transactions-item-component.right {
    justify-content: center;
  }

  .transactions-item-component .transactions-stage-container.left {
    right: calc(100% - 1px);
    transform: translateX(50%);
  }
  
  .transactions-item-component .transactions-stage-container.right {
    left: 1px;
    transform: translateX(-50%);
  }

  .transactions-item-component .item-content {
    width: 100%;
    margin-bottom: 10px;
  }

  .operations-history-item-component .item-content .item-icon-container {
    width: 25px;
    height: 25px;
    margin: 10px 0;
  }
}
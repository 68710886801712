.courses-add-update-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.192);
}

.courses-add-update-component .courses-add-update-content {
  width: 60%;
  max-width: 750px;
  height: 75%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border-radius: 15px;
  gap: 10px;
  padding: 30px 20px;
  background-color: white;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  position: relative;
  overflow-y: auto;
}

.courses-add-update-component .courses-add-update-content .new-courses-header {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.courses-add-update-component .courses-add-update-content .new-courses-header .save-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: none;
}

.courses-add-update-component .courses-add-update-content .new-courses-header .save-loader.active {
  width: 20px;
  height: 20px;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.courses-add-update-component .new-courses-body {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.courses-add-update-content .new-courses-body.loading,
.courses-add-update-content .new-courses-footer.loading {
  opacity: 0.5;
  pointer-events: none;
}

.courses-add-update-component .new-courses-body .item-container {
  width: 100%;
}

.courses-add-update-component .new-courses-body .item-container .description-input {
  font-weight: bold;
  width: 100%;
  min-height: 100px;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 10px;
  resize: none;
  background-color: transparent;
  border: 1px solid black;
  margin-top: 10px;
  direction: rtl;
}

.courses-add-update-component .new-courses-body .item-container .selector-label {
  font-size: 14px;
  font-weight: bold;
}

.courses-add-update-component .new-courses-body .item-container .selector-label span {
  color: var(--danger);
}

.courses-add-update-component .new-courses-message {
  width: 100%;
  padding: 0 5px;
  border-radius: 10px;
}

.courses-add-update-component .new-courses-footer {
  width: 100%;
  position: relative;
  /* bottom: 25px; */
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: end;
  gap: 15px;
  padding: 10px 25px 10px 10px;
}

.courses-add-update-component .new-courses-add-button-container,
.courses-add-update-component .new-courses-cancel-button-container {
  width: 100px;
  height: 40px;
}

.courses-add-update-component .new-courses-add-button-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 900px) {
  .courses-add-update-component {
    height: 100vh;
  }

  .courses-add-update-component .courses-add-update-content {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: 100vh;
    border-radius: 0;
  }
}

@media (min-width: 601px) and (max-width: 900px) {

}

@media (min-width: 901px) {

}

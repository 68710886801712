.admins-header-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.admins-card-container,
.new-admin-button {
  width: 23%;
  height: 100%;
}

.admins-card-container.new-admin-container.disable {
  opacity: 0.7;
  pointer-events: none;
}

.new-admin-button {
  color: inherit;
}

@media (max-width: 600px) {
  .admins-header-component {
    flex-direction: column;
  }

  .admins-card-container,
  .new-admin-button {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .admins-header-component {
    flex-wrap: wrap;
    gap: 10px;
  }

  .admins-card-container,
  .new-admin-button {
    width: calc(50% - 10px);
    height: 180px;
  }
}
.user-avater-container {
  width: 160px;
  height: 40px;
  margin-right: 10px;
}

.username-image-container {
  width: 100%;
  height: 100%;
  cursor: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
  gap: 5px;
  padding: 5px;
} 

.username-image-container .profile-initials {
  background-color: gray;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.username-image-container img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  background-color: gray;
}

.username-image-container span {
  width: 110px;
  font-size: 16px;
  text-transform: lowercase;
  font-weight: medium;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--second);
  transition: all 0.3s;
}

.username-image-container span:hover {
  color: var(--second);
}

@media (max-width: 600px) {
  .user-avater-container {
    width: 30px;
    height: 30px;
  }

  .username-image-container {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .username-image-container .profile-initials {
    width: 100%;
    height: 100%;
  }

  .username-image-container img {
    width: 90%;
    height: 90%;
  }  

  .user-avater-container span {
    display: none;
  }
}

.admins-body-component {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  padding: 25px;
  background-color: var(--bgColor);
  column-gap: 20px;
  position: relative;
}


.admin-card-container {
  height: 300px;
  flex-grow: 1;
}

@media (max-width: 600px) {
  .admins-body-component {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .admins-body-component {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 901px) {
  .admins-body-component {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1100px) {
  .admins-body-component {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1500px) {
  .admins-body-component {
    grid-template-columns: repeat(5, 1fr);
  }
}
:root {
  scroll-behavior: smooth;
  --primary: #4154f1;
  --second: #717ff5;
  --bgColor: #f6f9ff;
  --success: #37ac3b;
  --bgSuccess:#DDFFDD;
  --info: #42aaff;
  --bgInfo: #DDFFFF;
  --danger: hsla(355, 80%, 52%, 1.0);
  --bgDanger: hsla(355, 80%, 52%, 0.20);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  font-family: Nfont, Dfont;
  color: #444444;
  background-color: var(--bgColor);
  height: 100vh;
  overflow: auto;
}

a,
.link {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--second);
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

.container {
  width: 100%; /* العرض الافتراضي */
  height: 200px; /* الارتفاع الافتراضي */
}

@media (max-width: 600px) {

}

@media (min-width: 601px) and (max-width: 900px) {

}

@media (min-width: 901px) {

}

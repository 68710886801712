.general-question-header-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.general-question-header-component .general-questions-card-container {
  width: 23%;
  height: 100%;
}

@media (max-width: 600px) {
  .general-question-header-component {
    flex-direction: column;
  }

  .general-question-header-component .general-questions-card-container {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .general-question-header-component {
    flex-wrap: wrap;
    gap: 10px;
  }

  .general-question-header-component .general-questions-card-container {
    width: calc(50% - 10px);
  }
}
.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.login-container .form {
  width: 500px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  border-radius: 5px;
  padding: 50px 0;
  position: relative;
}

.form .login-title {
  font-size: 36px;
  color: var(--primary);
  margin-bottom: 20px;
}

.message-label-container {
  width: 80%;
  margin-bottom: 10px;
}

.email-username-input-container {
  width: 80%;
  height: 60px;
  margin-bottom: 30px;
}

.password-container {
  width: 80%;
  height: 60px;
  margin-bottom: 20px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 80%;
}

.button-container {
  width: 80%;
  height: 45px;
  margin: 20px 0;
}

.login-container .form .copyright {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  bottom: -30px;
}

@media (max-width: 600px) {
  .login-container .form {
    /* width: 300px; */
    background-color: transparent;
    box-shadow: none;
  }

  .email-username-input-container {
    width: 90%;
  }

  .message-label-container {
    width: 90%;
  }

  .password-container {
    width: 90%;
  }

  .checkbox-container {
    width: 90%;
  }

  .button-container {
    width: 90%;
  }

  .login-container .form .copyright {
    font-size: 14px;
    width: 75%;
  }
}
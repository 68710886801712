.course-section-header-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 5px;
  direction: rtl;
  padding: 10px;
}

.course-section-header-component .course-img-container {
  width: 350px;
  height: 250px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 5px;
}

.course-section-header-component .course-img-container .cover-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 5px;
}

.course-section-header-component .course-img-container .no-cover {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
}

.course-section-header-component .course-info-container {
  width: calc(100% - 350px);
  height: 100%;
  padding: 25px 25px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.course-section-header-component .course-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.course-section-header-component .course-info-container .course-title {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.course-section-header-component .course-info-container .course-description {
  font-size: 14px;
}

.course-section-header-component .course-info-container .sections-count-container {
  direction: ltr;
  text-align: left;
  padding: 5px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.course-section-header-component .course-info-container .sections-count-container .playlist-icon {
  font-size: 26px;
}

.course-section-header-component .course-section-footer-container {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.course-section-header-component .course-info-container .teacher-name {
  /* width: 100%; */
  padding: 7px;
  text-align: left;
  /* font-weight: bold; */
}

.course-section-header-component .course-info-container .course-section-footer-container .course-status {
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}

.course-section-header-component .course-info-container .course-section-footer-container .course-status.active {
  background-color: var(--success);
}

.course-section-header-component .course-info-container .course-section-footer-container .course-status.in-active {
  background-color: var(--danger);
}

.course-section-header-component .course-info-container .course-section-footer-container .addition-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.course-section-header-component .course-info-container .course-section-footer-container .addition-card-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.course-section-header-component .course-info-container .course-section-footer-container .addition-card-container .add-icon {
  font-size: 44px;
}

@media (min-width: 601px) and (max-width: 900px) {
  .course-section-header-component .course-img-container {
    height: 350px;
  }
}

@media (max-width: 900px) {
  .course-section-header-component {
    flex-direction: column;
  }

  .course-section-header-component .course-img-container {
    width: 100%;
  }

  .course-section-header-component .course-info-container {
    width: 100%;
    padding: 10px;
  }
  
  .course-section-header-component .course-info {
    width: 100%;
  }

  .course-section-header-component .course-info-container .course-title,
  .course-section-header-component .course-info-container .course-description {
    font-size: 14px;
  }
}
.permissions-component {
  width: 100%;
  min-height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.permissions-header-container {
  width: 100%;
  padding: 10px 20px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permissions-header-label {
  font-size: 18px;
  font-weight: bold;
  color: #444444;
}

.permissions-component .header-icon-container {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-permissions-icon {
  font-size: 24px;
  color: #444444;
  cursor: pointer;
  transition: all 0.1s ease;
}

.edit-permissions-icon.desabled {
  display: none;
}

.edit-permissions-icon:hover {
  color: black;
}

.permissions-body-container {
  text-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 10px;
}

.permissions-body-container .permissions-items-container {
  width: 100%; 
  color: #444444;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.permissions-items-container .permission {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
  transition: all 0.2s ease;
  padding: 5px 0;
}

.permissions-items-container .permission:hover {
  background-color: var(--bgColor);
  color: #444444;
}

.permissions-items-container .permission:hover .permission-accept-icon {
  color: #444444;
}

.permission .permission-accept-icon {
  color: var(--success);
  width: 25px;
  height: 25px;
}

.permission .permission-title {
  width: calc(100% - 25px);
  font-size: 18px;
  text-transform: capitalize;
  text-wrap: wrap;
  cursor: default;
}

.permissions-items-container .no-permissions {
  color: var(--success);
  font-weight: bold;
}

.set-permissions-container {
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  display: none;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  border-top: 1px solid black;
  padding-top: 5px;
  gap: 5px;
}

.set-permissions-container.desabled {
  display: none;
}

.set-permissions-container.write-mode {
  display: flex;
}

.set-permissions-container .permission-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.2s ease;
  padding: 5px 0;
}

.set-permissions-container .permission-input-container:hover {
  background-color: var(--bgColor);
}

.permissions-body-container .permission-input {
  width: 25px;
  min-height: 20px;
  cursor: pointer;
}

.permissions-body-container .permission-input-label {
  width: calc(100% - 25px);
  font-size: 18px;
  text-transform: capitalize;
  text-wrap: wrap;
  cursor: pointer;
}

.permissions-done-container {
  width: 25%;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.permissions-done {
  color: var(--primary);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 10px 20px;
}

.permissions-done.loading {
  opacity: 0.5;
  pointer-events: none;
}

.permissions-done:hover {
  color: white;
  background-color: var(--primary);
}

.set-permissions-loader {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: none;
}

.set-permissions-loader.active {
  width: 20px;
  height: 20px;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .permissions-body-container .permission-input-label {
    font-size: 16px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {

}

@media (min-width: 901px) {

}